import React from "react";
import { Link } from "theme-ui";
import { documentToReactComponents } from "@contentful/rich-text-react-renderer";
import { INLINES, Document } from "@contentful/rich-text-types";

type IProps = {
  doc: Document;
};

export default function RichTextRenderer(props: IProps) {
  return (
    <>
      {documentToReactComponents(props.doc, {
        renderNode: {
          [INLINES.HYPERLINK]: (node, children) => <Link href={node.data.uri}>{children}</Link>,
        },
      })}
    </>
  );
}
